import getLoanPositionDtls from "../../apis/get-loan-position-dtls/get-loan-position-dtls";
import getTrenchReport from "../../apis/get-trench-report/get-trench-report";
import summary from "../../apis/summary"
import vdSummaryDtls from "../../apis/vd-summary-dtls/vd-summary-dtls";
import commonFunctionObject from "../../common-function/commonfunctions";
import convertOfferToOpportunity from "../../apis/convert-offer-to-opportunity/convert-offer-to-opportunity";
import getOffers from "../../apis/get-offers/get-offers";
import jocataPreApproved from "../../apis/jocata-pre-approved";
import clientStatement from "../../apis/client-statement"
import holdingStatement from "../../apis/holding-statement"
import extractDoc from "../../apis/extract-doc"
import getBoGetUrl from "../../apis/bo-get-url"
import serviceRequest from "../../apis/service-request/service-request"
import generateInterestCertificate from '../../apis/generate-interest-certificate'
import getContractPgsDetails from "../../apis/get-contract-pgs-details/get-contract-pgs-details";
import apiConfigObj from "../../app-config/app-config";
import updatePaymentTransactionStatus from "../../apis/update-payment-transaction-status/update-payment-transaction-status";
import retailUpdatePaymentTransStatus from "../../apis/retail-update-payment-transaction-status";

import getFcPgsUrl from "../../apis/get-fc-pgs-url/get-fc-pgs-url";
import getFcNonRetentionLetter from "../../apis/fc-non-retention-letter/fc-non-retention-letter";
import getAgreementAndAnnexure from '../../apis/get-agreement-and-annexure'
import getEnachDetails from '../../apis/get-enach-details'
import saveEMandate from '../../apis/save-emandate'
import enachLog from '../../apis/log-ui-url'
import updateMandateStatus from '../../apis/update-emandate-status'
import { useContext } from "react";
import DataMapping from "../../context/data-mapping";
import billPayLink from "../../apis/get-bill-pay-link/get-bill-pay-link";
import getAssetDetails from "../../apis/asset-details/asset-details";
import jsHelper from "../../utilities/jsHelper";
import whatsAppServicing from "../../apis/Whats-app-servcing/Whats-app-servcing";
import getReferralDetails from "../../apis/get-referral-details";
import searchMaster from "../../apis/search-master";
import clientProfileDetails from "../../apis/client-profile-details";
import clientHoldingDetails from "../../apis/client-holdings-details";
import getPaytmCustomerDetails from "../../apis/get-paytm-customer-details";
import getCustomerSegmentationDetails from "../../apis/customer-segmentation-details/customer-segmentation-details";
import getChatBotCustomerDetails from "../../apis/chatbot-api";


let base64 = require('base-64');
var personalLoan = "PERSONAL LOAN";
var businessLoan = "BUSINESS LOAN";
var usedCarLoan = "AUTO LOAN";
var twoWheelerLoan = "TWO WHEELER";
var consumerDurabels = "CONSUMER DURABLES";
var homeLoan = "HOME LOAN";
var preapprovedPLStatus;
var preapprovedBlStatus;
var preApprovedOffer = [{
    "title": "Personal Loan",
    "productName": "Personal Loan",
    "imgIcon": "images/icons/icons-16.png",
    "description": "Fulfil Your financial Needs",
    "applyNowBtn": "https://www.tatacapital.com/personal-loan/learn-about-personal-loan.html",
    "knowMore": "https://www.tatacapital.com/personal-loan/learn-about-personal-loan.html",
    "offerID": "",
    "serviceChange": false
},
{
    "title": "Home Loan",
    "productName": "",
    "imgIcon": "images/icons/icons-17.png",
    "description": "Customized Home loan solutions",
    "applyNowBtn": "https://www.tatacapital.com/online/loans/home-loans/#/apply-now-home-loan.html",
    "knowMore": "https://www.tatacapital.com/home-loan/learn-about-home-loan.html",
    "serviceChange": false
},
{
    "title": "Used Car Loan",
    "productName": "AUTO LOAN",
    "imgIcon": "images/icons/icons-18.png",
    "description": "Financing with easy repayment options",
    "applyNowBtn": "https://www.tatacapital.com/used-car-loan/apply-now-used-car-loan.html",
    "knowMore": "https://www.tatacapital.com/vehicle-loan/used-car-loan/learn-about-used-car-loan.html",
    "serviceChange": false
},
{
    "title": "Business Loan",
    "productName": "",
    "imgIcon": "images/icons/icons-19.png",
    "description": "Avail loans to suit your business requirements",
    "applyNowBtn": "https://www.tatacapital.com/online/loans/business-loans/home#!",
    "knowMore": "https://www.tatacapital.com/business-loan/learn-about-business-loan.html",
    "serviceChange": false
}
];


var serviceDetails;

const OverviewServiceObject = {

}

const getLoanPositionDTLSService = (getLoanRequest, preApi) => {
    return new Promise((resolve, reject) => {
        var callGetLoanPositionDtls = getLoanPositionDtls(getLoanRequest)
        preApi.push(callGetLoanPositionDtls)
        callGetLoanPositionDtls.then(response => {
            if (response.header.status.toUpperCase() === "SUCCESS") {
                resolve(response)
                // if (response.body.getLoanPositionDtlsReturn.errorMsg === "") {
                // } else {
                //     TODO: Show Error Message
                // }
            } else {
                //TODO: Show Error Message
            }
        });
    })
}

OverviewServiceObject.getLoanPositionDTLSService = getLoanPositionDTLSService

const summaryService = (requestObj, lasData, preApi) => {
    return new Promise((resolve, reject) => {
        var ScheduleDetails = [];
        var lasAccountInfo = [];
        var callSummaryApi = summary(requestObj)
        preApi.push(callSummaryApi)
        callSummaryApi.then((summaryResponse) => {

            if (summaryResponse.header.status == "SUCCESS") {
                if (summaryResponse.body.RetStatus == "SUCCESS") {
                    var summaryData = summaryResponse.body.LASGetSummaryData_Response.summary;
                    summaryData.forEach(function (summaryDataObj, index) {
                        var contractDetails = summaryDataObj;
                        var loanData = summaryDataObj.LoanAccount;
                        var contractData = {};
                        var loanAccountData = [];
                        var localStringObj = {
                            style: "currency",
                            currency: "INR",
                            decimal: '2'
                        }
                        loanData.forEach(function (value) {
                            var loanAccountDataObj = {};
                            let ProductCategory = ["Loan Against Mutual Funds _Digital","Loan Against Shares _Digital", "Loan Against Mutual Funds"]
                            if (ProductCategory.includes(value?.ProductCategory)) {
                                loanAccountDataObj['Account_Number'] = (value?.Account_Number) ? Number(String(value?.Account_Number)) : '-';
                                    loanAccountDataObj['LoanAccountName'] = (value?.LoanAccountName) ? String(value?.LoanAccountName) : '';
                                    loanAccountDataObj['product'] = (value?.Product) ? String(value?.Product) : '';
                                    loanAccountDataObj['Primary_Borrower'] = (value?.Primary_Borrower) ? String(value?.Primary_Borrower) : '';
                                    loanAccountDataObj['AccountOpening_Date'] = (value?.AccountOpening_Date) ? String(value?.AccountOpening_Date) : '-';
                                    loanAccountDataObj['sanctionedamount'] = (value?.SanctionedLimit) ? Number(String(value?.SanctionedLimit)) : '0';
                                    loanAccountDataObj['availabledrawingpower'] = ((value?.DrawingPower - value?.PrincipalOutstanding) > 0) ? Number(String(value?.DrawingPower - value?.PrincipalOutstanding)) : '0';

                                    loanAccountDataObj['marginShortFall'] = ((value?.DrawingPower - (parseFloat(value?.TotalOutstanding) + parseFloat(value?.ScheduleDetails[0].InterestAccruedNotDue) + parseFloat(value?.ScheduleDetails[0].PenalInterestAccruedNotDue))) < 0)
                                        ? Number(String(value?.DrawingPower - (parseFloat(value?.TotalOutstanding) + parseFloat(value?.ScheduleDetails[0].InterestAccruedNotDue) + parseFloat(value?.ScheduleDetails[0].PenalInterestAccruedNotDue))))
                                        : '0';
                                    loanAccountDataObj['drawingPower'] = (value?.DrawingPower) ? Number(String(value?.DrawingPower)) : '0';
                                    loanAccountDataObj['principleoutstanding'] = (value?.PrincipalOutstanding) ? Number(String(value?.PrincipalOutstanding)) : '0';
                                    loanAccountDataObj['AmountDue'] = (value?.AmountDue) ? Number(String(value?.AmountDue)) : '0';

                                    loanAccountDataObj['TotalOutstanding'] = (value?.TotalOutstanding) ? Number(String(parseFloat(value?.TotalOutstanding) + ((value?.ScheduleDetails[0].InterestAccruedNotDue) ? parseFloat(Math.ceil(value?.ScheduleDetails[0].InterestAccruedNotDue)) : 0) + ((value?.ScheduleDetails[0].PenalInterestAccruedNotDue) ? parseFloat(Math.ceil(value?.ScheduleDetails[0].PenalInterestAccruedNotDue)) : 0))) : '0';
                                    loanAccountDataObj['AmountAvailable_ForDisbursement'] = (value?.AmountAvailable_ForDisbursement) ? Number(String(value?.AmountAvailable_ForDisbursement)) : '0';
                                    loanAccountDataObj['OutstandingInterest'] = (value?.OutstandingInterest) ? Number(String(value?.OutstandingInterest)) : '0';
                                    loanAccountDataObj['OutstandingCharges'] = (value?.OutstandingCharges) ? Number(String(value?.OutstandingCharges)) : '0';
                                    loanAccountDataObj['PrimaryHolder'] = (value?.PrimaryHolder) ? String(value?.PrimaryHolder) : '';
                                    loanAccountDataObj['AccountMaturityDate'] = (value?.AccountMaturityDate) ? String(value?.AccountMaturityDate) : '-';
                                    loanAccountDataObj['ProductCategory'] = (value?.ProductCategory) ? String(value?.ProductCategory) : '';
                                    loanAccountDataObj['Primary_AccountHolder'] = (value?.Primary_AccountHolder) ? String(value?.Primary_AccountHolder) : '';
                                    loanAccountDataObj['Coborrowers'] = (value?.Coborrowers) ? String(value?.Coborrowers) : '';
                                    loanAccountDataObj['ECSApplicable'] = (value?.ECSApplicable) ? String(value?.ECSApplicable) : '';
                                    loanAccountDataObj['Address_Correspondence'] = (value?.Address_Correspondence) ? String(value?.Address_Correspondence) : '';
                                    loanAccountDataObj['Address_Office'] = (value?.Address_Office) ? String(value?.Address_Office) : '';
                                    loanAccountDataObj['AgreementNo'] = (value?.AgreementNo) ? String(value?.AgreementNo) : '';
                                    loanAccountDataObj['AccountStatus'] = (value?.AccountStatus) ? String(value?.AccountStatus) : '';
                                    loanAccountDataObj['PendingTDS'] = (value?.PendingTDS) ? Number(String(value?.PendingTDS)) : '0';
                                    loanAccountDataObj['TotalPortfolioValue?'] = (value?.TotalPortfolioValue) ? parseFloat(String(value?.TotalPortfolioValue)).toFixed('2') : '0';
                                    loanAccountDataObj['ProductCategory'] = (value?.ProductCategory) ? String(value?.ProductCategory) : '';
                                    if ((value?.ScheduleDetails) && Object.keys(value?.ScheduleDetails).length !== 0) {
                                        value?.ScheduleDetails.forEach(function (data, index) {
                                            var ScheduleDetailsObj = {};
                                            ScheduleDetailsObj['AmountDue_ForCustomer'] = (data.AmountDue_ForCustomer) ? + Number(String(data.AmountDue_ForCustomer)) : '0';
                                            ScheduleDetailsObj['AmountOverdue_ForCustomer'] = (data.AmountOverdue_ForCustomer) ? + Number(String(data.AmountOverdue_ForCustomer)) : '0';
                                            ScheduleDetailsObj['ChargesOutstanding'] = (data.ChargesOutstanding) ? + Number(String(data.ChargesOutstanding)) : '0';
                                            ScheduleDetailsObj['ChargesPosting'] = (data.ChargesPosting) ? String(data.ChargesPosting) : '';
                                            ScheduleDetailsObj['UnbilledInterest'] = (data.InterestAccruedNotDue) ? Math.ceil(Number(data.InterestAccruedNotDue)).toString() : '0';
                                            ScheduleDetailsObj['PenalInterestAccruedNotDue'] = (data.PenalInterestAccruedNotDue) ? Math.ceil(Number(data.PenalInterestAccruedNotDue)).toString() : '0';

                                            ScheduleDetailsObj['Grace_Period_Principal'] = (data.Grace_Period_Principal) ? + Number(String(data.Grace_Period_Principal)) : '0';
                                            ScheduleDetailsObj['GracePeriod_Interest'] = (data.GracePeriod_Interest) ? + Number(String(data.GracePeriod_Interest)) : '0';
                                            ScheduleDetailsObj['Interest_Frequency'] = (data.Interest_Frequency) ? String(data.Interest_Frequency) : '';
                                            ScheduleDetailsObj['Interest_Outstanding'] = (data.Interest_Outstanding) ? + Number(String(data.Interest_Outstanding)) : '0';
                                            ScheduleDetailsObj['InterestRate'] = (data.InterestRate) ? + Number(String(data.InterestRate)) : '0';
                                            ScheduleDetailsObj['InterestType'] = (data.InterestType) ? String(data.InterestType) : '';
                                            ScheduleDetailsObj['LoanAmount'] = (data.LoanAmount) ? + Number(String(data.LoanAmount)) : '0';
                                            ScheduleDetailsObj['LoanLimit'] = (data.LoanLimit) ? + Number(String(data.LoanLimit)) : '0';
                                            ScheduleDetailsObj['LoanMaturityDate'] = (data.LoanMaturityDate) ? String(data.LoanMaturityDate) : '-';
                                            ScheduleDetailsObj['LoanNumber'] = (data.LoanNumber) ? Number(String(data.LoanNumber)) : '0';
                                            ScheduleDetailsObj['LoanType'] = (data.LoanType) ? String(data.LoanType) : '';
                                            ScheduleDetailsObj['OverdueInterest'] = (data.OverdueInterest) ? String(data.OverdueInterest) : '';
                                            ScheduleDetailsObj['OverduePrincipal'] = (data.OverduePrincipal) ? String(data.OverduePrincipal) : '';
                                            ScheduleDetailsObj['PenalInterest'] = (data.PenalInterest) ? + Number(String(data.PenalInterest)) : '0';
                                            ScheduleDetailsObj['PenalRateInterest'] = (data.PenalRateInterest) ? + Number(String(data.PenalRateInterest)) : '0';
                                            ScheduleDetailsObj['PenalRatePrincipal'] = (data.PenalRatePrincipal) ? + Number(String(data.PenalRatePrincipal)) : '0';
                                            ScheduleDetailsObj['PrincipalFrequency'] = (data.PrincipalFrequency) ? String(data.PrincipalFrequency) : '';
                                            ScheduleDetailsObj['Purpose_of_Loan'] = (data.Purpose_of_Loan) ? String(data.Purpose_of_Loan) : '';
                                            ScheduleDetailsObj['ReviewPeriod'] = (data.ReviewPeriod) ? String(data.ReviewPeriod) : '';
                                            ScheduleDetailsObj['SubType'] = (data.SubType) ? String(data.SubType) : '';
                                            ScheduleDetails.push(ScheduleDetailsObj);
                                        });
                                        loanAccountDataObj['ScheduleDetails'] = ScheduleDetails;
                                    }
                                    loanAccountDataObj['system'] = 'las';
                                    if (loanAccountDataObj.AccountStatus) {
                                        if (loanAccountDataObj.AccountStatus.toLowerCase() == 'active')
                                            loanAccountData.push(loanAccountDataObj);
                                    }
                            }

                        });
                        contractData['CustomerID'] = contractDetails.CustomerID;
                        contractData['RMName'] = summaryDataObj.RMName;
                        contractData['MobileNo'] = summaryDataObj.MobileNo;
                        contractData['EmailID'] = summaryDataObj.EmailID;
                        contractData['ServiceBranch'] = summaryDataObj.ServiceBranch;
                        contractData['HeadofFamily'] = summaryDataObj.HeadofFamily;
                        contractData['Address'] = summaryDataObj.Address;

                        contractData['City'] = summaryDataObj.City;
                        contractData['State'] = summaryDataObj.State;
                        contractData['Country'] = summaryDataObj.Country;
                        contractData['MobileNumber'] = summaryDataObj.MobileNumber;
                        contractData['mailID'] = summaryDataObj.mailID;
                        contractData['Office_Address'] = summaryDataObj.Office_Address;
                        contractData['Office_City'] = summaryDataObj.Office_City;
                        contractData['Office_State'] = summaryDataObj.Office_State;
                        contractData['Office_Country'] = summaryDataObj.Office_Country;
                        contractData['Office_Telephone'] = summaryDataObj.Office_Telephone;
                        contractData['DefaultMailing'] = summaryDataObj.DefaultMailing;
                        contractData['company'] = summaryDataObj.company;
                        contractData['gcid'] = lasData[index].GCID;
                        contractData['LoanAccount'] = loanAccountData;
                        contractData['system'] = "las";
                        if (contractDetails.BankDetails) {
                            let bankData = contractDetails.BankDetails.filter(bankData => bankData.DefaultAccount.toLowerCase() === "yes")
                            if (bankData.length > 0) {
                                contractData['bankName'] = bankData[0].BankName;
                                contractData['ifscCode'] = bankData[0].IFSCCode;
                                contractData['bankAccountNumber'] = bankData[0].BankAccountNumber;
                            }
                        }
                        lasAccountInfo.push(contractData);

                    });

                    resolve(lasAccountInfo)
                } else {
                    reject("L12")
                }
            } else {
                reject("L-12")
            }
        }).catch((error) => {

        })
    })
}

OverviewServiceObject.summaryService = summaryService


const vdSummaryDTLSService = (requestObj, contractNo, preApi) => {
    return new Promise((resolve, reject) => {
        var callCDSummaryApi = vdSummaryDtls(requestObj)
        preApi.push(callCDSummaryApi)
        callCDSummaryApi.then((vdSummaryDtlsResponse) => {
            if (vdSummaryDtlsResponse) {
                if (vdSummaryDtlsResponse.header.status.toUpperCase() === "SUCCESS") {
                    if (vdSummaryDtlsResponse?.body?.["ns1:MT_TW_TSl_MOD_RESP"]) {
                        var summaryDTLSReturnArray = vdSummaryDtlsResponse.body["ns1:MT_TW_TSl_MOD_RESP"].RETURN.ITEM;
                    } else {
                        var tdsTransactionResponseArray = vdSummaryDtlsResponse.body.IT_ZTW_TALIMIT2.ITEM;
                        var tdsTransactionObj = {}
                    }
                    if (!(vdSummaryDtlsResponse?.body?.["ns1:MT_TW_TSl_MOD_RESP"])) {
                        if (Array.isArray(tdsTransactionResponseArray)) {
                            var filteredArrItem = tdsTransactionResponseArray.filter(function (element) {
                                var lifnr = appendCCodAccNo(String(element.LIFNR));
                                return lifnr === contractNo;
                            });
                        } else if (typeof tdsTransactionResponseArray === "object") {
                            var filteredArrItem = new Array(tdsTransactionResponseArray).filter(function (element) {
                                var lifnr = appendCCodAccNo(String(element.LIFNR));
                                return lifnr === contractNo;
                            });;
                        } else {
                            var filteredArrItem = [];
                        }
                        if (filteredArrItem.length > 0) {
                            var tdsTransactionArray = filteredArrItem[0];
                            var localStringObj = {
                                style: "currency",
                                currency: "INR",
                                decimal: '2'
                            }
                            tdsTransactionObj["TOT_AMT"] = (tdsTransactionArray.TOT_AMT) ? Number(String(tdsTransactionArray.TOT_AMT)) : '0';
                            tdsTransactionObj["Z121_150"] = (tdsTransactionArray.Z121_150) ? String(tdsTransactionArray.Z121_150) : '0';
                            tdsTransactionObj["MOD_AVAIL_LIMIT"] = (tdsTransactionArray.MOD_AVAIL_LIMIT) ? String(tdsTransactionArray.MOD_AVAIL_LIMIT) : '-';
                            tdsTransactionObj["ADHOC_LIMIT"] = (tdsTransactionArray.ADHOC_LIMIT) ? String(tdsTransactionArray.ADHOC_LIMIT) : '-';
                            tdsTransactionObj["B_IND"] = (tdsTransactionArray.B_IND) ? String(tdsTransactionArray.B_IND) : '-';
                            tdsTransactionObj["PROCESS_TIME"] = (tdsTransactionArray.PROCESS_TIME) ? String(tdsTransactionArray.PROCESS_TIME) : '-';
                            tdsTransactionObj["MANDT"] = (tdsTransactionArray.MANDT) ? String(tdsTransactionArray.MANDT) : '-';
                            tdsTransactionObj["MOD_PROCESS_DATE"] = (tdsTransactionArray.MOD_PROCESS_DATE) ? String(tdsTransactionArray.MOD_PROCESS_DATE) : '-';
                            tdsTransactionObj["PRODUCT"] = (tdsTransactionArray.PRODUCT) ? String(tdsTransactionArray.PRODUCT) : '-';
                            tdsTransactionObj["Z151_180"] = (tdsTransactionArray.Z151_180) ? String(tdsTransactionArray.Z151_180) : '0';
                            tdsTransactionObj["ACT_AVAIL_LIMIT"] = (tdsTransactionArray.ACT_AVAIL_LIMIT) ? String(tdsTransactionArray.ACCACT_AVAIL_LIMITESS_IND) : '-';
                            tdsTransactionObj["ZZONE"] = (tdsTransactionArray.ZZONE) ? String(tdsTransactionArray.ZZONE) : '-';
                            tdsTransactionObj["BEZEI"] = (tdsTransactionArray.BEZEI) ? String(tdsTransactionArray.BEZEI) : '-';
                            tdsTransactionObj["DISB_AMT"] = (tdsTransactionArray.DISB_AMT) ? String(tdsTransactionArray.DISB_AMT) : '-';
                            tdsTransactionObj["AGG_START_DT"] = (tdsTransactionArray.AGG_START_DT) ? String(tdsTransactionArray.AGG_START_DT) : '-';
                            tdsTransactionObj["Z1_5"] = (tdsTransactionArray.Z1_5) ? String(tdsTransactionArray.Z1_5) : '-';
                            tdsTransactionObj["SANC_LIMIT"] = (tdsTransactionArray.SANC_LIMIT) ? String(tdsTransactionArray.SANC_LIMIT) : '-';
                            tdsTransactionObj["INVOICE_AGE"] = (tdsTransactionArray.INVOICE_AGE) ? String(tdsTransactionArray.INVOICE_AGE) : '-';
                            tdsTransactionObj["MOD_PROCESS_TIME"] = (tdsTransactionArray.MOD_PROCESS_TIME) ? String(tdsTransactionArray.MOD_PROCESS_TIME) : '-';
                            tdsTransactionObj["Z121_180"] = (tdsTransactionArray.Z121_180) ? String(tdsTransactionArray.Z121_180) : '-';
                            tdsTransactionObj["Z6_10"] = (tdsTransactionArray.Z6_10) ? String(tdsTransactionArray.Z6_10) : '-';
                            tdsTransactionObj["AVAIL_LIMIT"] = (tdsTransactionArray.AVAIL_LIMIT) ? Number(String(tdsTransactionArray.AVAIL_LIMIT)) : '0';
                            tdsTransactionObj["PROCESS_DATE"] = (tdsTransactionArray.PROCESS_DATE) ? String(tdsTransactionArray.PROCESS_DATE) : '-';
                            tdsTransactionObj["OUT_BAL"] = (tdsTransactionArray.OUT_BAL) ? Number(String(tdsTransactionArray.OUT_BAL)) : '0';
                            tdsTransactionObj["ZUSER"] = (tdsTransactionArray.ZUSER) ? String(tdsTransactionArray.ZUSER) : '-';
                            tdsTransactionObj["MOD_USER"] = (tdsTransactionArray.MOD_USER) ? String(tdsTransactionArray.MOD_USER) : '-';
                            tdsTransactionObj["LIFNR"] = (tdsTransactionArray.LIFNR) ? String(tdsTransactionArray.LIFNR) : '-';
                            tdsTransactionObj["MANU_NAME"] = (tdsTransactionArray.MANU_NAME) ? String(tdsTransactionArray.MANU_NAME) : '-';
                            tdsTransactionObj["LOCATION"] = (tdsTransactionArray.LOCATION) ? String(tdsTransactionArray.LOCATION) : '-';
                            tdsTransactionObj["PEAK_REV_DT"] = (tdsTransactionArray.PEAK_REV_DT) ? String(tdsTransactionArray.PEAK_REV_DT) : '-';
                            tdsTransactionObj["TOTAL_REV_DT"] = (tdsTransactionArray.TOTAL_REV_DT) ? datechange(String(tdsTransactionArray.TOTAL_REV_DT)) : '-';
                            tdsTransactionObj["DOWNLOAD_IND"] = (tdsTransactionArray.DOWNLOAD_IND) ? String(tdsTransactionArray.DOWNLOAD_IND) : '-';
                            tdsTransactionObj["MAX_DAYS"] = (tdsTransactionArray.MAX_DAYS) ? String(tdsTransactionArray.MAX_DAYS) : '-';
                            tdsTransactionObj["TOT_OD_AMT"] = (tdsTransactionArray.TOT_OD_AMT) ? Number(String(tdsTransactionArray.TOT_OD_AMT)) : '0';
                            tdsTransactionObj["PEAK_LIMIT"] = (tdsTransactionArray.PEAK_LIMIT) ? String(tdsTransactionArray.PEAK_LIMIT) : '-';
                            tdsTransactionObj["UTIL_LIMIT"] = (tdsTransactionArray.UTIL_LIMIT) ? Number(String(tdsTransactionArray.UTIL_LIMIT)) : '0';
                            tdsTransactionObj["Z0_40"] = (tdsTransactionArray.Z0_40) ? String(tdsTransactionArray.Z0_40) : '0';
                            tdsTransactionObj["BUKRS"] = (tdsTransactionArray.BUKRS) ? String(tdsTransactionArray.BUKRS) : '-';
                            tdsTransactionObj["REP_TIME"] = (tdsTransactionArray.REP_TIME) ? String(tdsTransactionArray.REP_TIME) : '-';
                            tdsTransactionObj["TOTAL_LIMIT"] = (tdsTransactionArray.TOTAL_LIMIT) ? Number(String(tdsTransactionArray.TOTAL_LIMIT)) : '0';
                            tdsTransactionObj["OPEN_BAL"] = (tdsTransactionArray.OPEN_BAL) ? String(tdsTransactionArray.OPEN_BAL) : '0';
                            tdsTransactionObj["Z41_45"] = (tdsTransactionArray.Z41_45) ? String(tdsTransactionArray.Z41_45) : '0';
                            tdsTransactionObj["KONZS"] = (tdsTransactionArray.KONZS) ? String(tdsTransactionArray.KONZS) : '-';
                            tdsTransactionObj["Z76_90"] = (tdsTransactionArray.Z76_90) ? String(tdsTransactionArray.Z76_90) : '0';
                            tdsTransactionObj["Z61_75"] = (tdsTransactionArray.Z61_75) ? String(tdsTransactionArray.Z61_75) : '0';
                            tdsTransactionObj["SANC_REV_DT"] = (tdsTransactionArray.SANC_REV_DT) ? String(tdsTransactionArray.SANC_REV_DT) : '-';
                            tdsTransactionObj["MANU_CO_CODE"] = (tdsTransactionArray.MANU_CO_CODE) ? String(tdsTransactionArray.MANU_CO_CODE) : '-';
                            tdsTransactionObj["NAME1"] = (tdsTransactionArray.NAME1) ? String(tdsTransactionArray.NAME1) : '-';
                            tdsTransactionObj["AGG_EXP_DT"] = (tdsTransactionArray.AGG_EXP_DT) ? String(tdsTransactionArray.AGG_EXP_DT) : '-';
                            tdsTransactionObj["Z91_120"] = (tdsTransactionArray.Z91_120) ? String(tdsTransactionArray.Z91_120) : '0';
                            tdsTransactionObj["PROGRAMNAME"] = (tdsTransactionArray.PROGRAMNAME) ? String(tdsTransactionArray.PROGRAMNAME) : '-';
                            tdsTransactionObj["Z46_60"] = (tdsTransactionArray.Z46_60) ? String(tdsTransactionArray.Z46_60) : '0';
                            tdsTransactionObj["Z181"] = (tdsTransactionArray.Z181) ? String(tdsTransactionArray.Z181) : '0';
                            tdsTransactionObj["ADHOC_REVDT"] = (tdsTransactionArray.ADHOC_REVDT) ? String(tdsTransactionArray.ADHOC_REVDT) : '-';
                            tdsTransactionObj["TMLLIFNR"] = (tdsTransactionArray.TMLLIFNR) ? String(tdsTransactionArray.TMLLIFNR) : '-';
                            resolve(tdsTransactionObj)
                        } else {
                            reject("C-12")
                        }
                    } else {
                        if (Array.isArray(summaryDTLSReturnArray)) {
                            summaryDTLSReturnArray.forEach(function (ele) {
                                if ((ele.MESSAGE.search("No Authorization to Run") > -1) || (ele.MESSAGE.search("No Data found") > -1) || (ele.MESSAGE.search("No Records selected for the given criteria") > -1)) {
                                    reject("C-12")
                                }
                            })
                        } else {
                            if ((summaryDTLSReturnArray.MESSAGE.search("No Authorization to Run") > -1) || (summaryDTLSReturnArray.MESSAGE.search("No Data found") > -1) || (summaryDTLSReturnArray.MESSAGE.search("No Records selected for the given criteria") > -1)) {
                                reject("C-12")
                            }
                        }
                    }

                } else {
                    reject("C-12")
                }
            }
        }).catch((error) => {

        })
    })
}
OverviewServiceObject.vdSummaryDTLSService = vdSummaryDTLSService


const trenchReportApiService = (requestObj, contractNo, preApi) => {
    return new Promise((resolve, reject) => {
        var callTrenchReportApi = getTrenchReport(requestObj)
        preApi.push(callTrenchReportApi)
        callTrenchReportApi.then((getTrenchReportResponse) => {
            if (getTrenchReportResponse) {
                if (getTrenchReportResponse.header.status.toUpperCase() === "SUCCESS") {
                    var trenchDataArray = getTrenchReportResponse.body.I_ZTW_TRENCH_REPORT.ITEM;
                    if (Array.isArray(trenchDataArray)) {
                        var filteredTrenchData = trenchDataArray.filter(function (data) {
                            var lifnr = appendCCodAccNo(String(data.LIFNR));
                            return lifnr === contractNo;
                        })
                    } else if (typeof trenchDataArray === "object") {
                        var filteredTrenchData = new Array(trenchDataArray).filter(function (data) {
                            var lifnr = appendCCodAccNo(String(data.LIFNR));
                            return lifnr === contractNo;
                        })
                    }
                    resolve(filteredTrenchData)
                } else {
                    reject("C-04")
                }
            } else {
                reject("C-05")
            }
        }).catch(function (error) {
            reject("C-06")
            //resolve(true);
        });

    }).catch((error) => {

    })
}
OverviewServiceObject.trenchReportApiService = trenchReportApiService


function datechange(date) {
    var changeDate = date.split("-");
    var newDateFormat = changeDate[2] + "-" + changeDate[1] + "-" + changeDate[0];
    return newDateFormat;
}


function appendCCodAccNo(accountNumber) {
    var dataAccNo = '';
    var accLength = accountNumber.length;
    if (accLength < 10) {
        var zeroLen = 10 - accLength;
        var preString = '';
        for (var i = 0; i < zeroLen; i++) {
            preString += '0';
        }
        dataAccNo = preString + '' + accountNumber;
        console.log(preString + '' + accountNumber);
    } else {
        dataAccNo = accountNumber;
    }
    return dataAccNo;
}


const getOffersService = (requestObj, mobileNumber, authToken, preApi, postpreApi) => {
    return new Promise((resolve, reject) => {
        var consumerDurabelsJson = {
            "title": "Consumer Loan",
            "imgIcon": "images/icons/consumerDurable.jpg",
            "description": "Attractive offers to make your purchase quick and easy",
            "applyNowBtn": "https://www.tatacapital.com/business-loan/apply-now-business-loan.html",
            "knowMore": "https://www.tatacapital.com/consumer-durable-loan.html",
            "offerID": "",
            "serviceChange": false
        }
        var callGetOffersApi = getOffers(requestObj)
        preApi.push(callGetOffersApi)
        callGetOffersApi.then(response => {

            if (response.header.status.toUpperCase() === "SUCCESS") {

                if (Object.keys(response).length !== 0 && response.body.records.length !== 0) {
                    var responseBody = typeof response["body"] === "object" ? response["body"] : {};
                    var getOffersRecord = responseBody.records;
                    let personalLoanFlag = false;
                    let businessLoanFlag = false;
                    getOffersRecord.forEach((data) => {
                        var loanName = data.Product__r.Name;
                        if (loanName.toLowerCase() === "personal loan") {
                            personalLoanFlag = true
                        } else if (loanName.toLowerCase() === "business loan") {
                            businessLoanFlag = true
                        }
                    });
                    if (personalLoanFlag && businessLoanFlag) {
                        let jocataServicePL = jocataService(mobileNumber, "personalLoan", authToken, postpreApi)
                        postpreApi.push(jocataServicePL)
                        jocataServicePL.then((jocataPreApprovedResponse) => {
                            preapprovedPLStatus = jocataPreApprovedResponse
                            let jocataServiceBL = jocataService(mobileNumber, "businessLoan", authToken, postpreApi)
                            postpreApi.push(jocataServiceBL)
                            return jocataServiceBL

                        }).then((jocataPreApprovedResponse) => {
                            preapprovedBlStatus = jocataPreApprovedResponse
                            let finalArray = restOfGetOffersFunction(responseBody, consumerDurabelsJson, mobileNumber, preapprovedPLStatus, preapprovedBlStatus)
                            resolve(finalArray)
                        }).catch(function (error) {
                            let finalArray = restOfGetOffersFunction(responseBody, consumerDurabelsJson, mobileNumber, true, true)
                            resolve(finalArray)
                        });
                    }
                    if (personalLoanFlag && !businessLoanFlag) {
                        let jocataServicePL = jocataService(mobileNumber, "personalLoan", authToken, postpreApi)
                        postpreApi.push(jocataServicePL)
                        jocataServicePL.then((jocataPreApprovedResponse) => {
                            preapprovedPLStatus = jocataPreApprovedResponse
                            let finalArray = restOfGetOffersFunction(responseBody, consumerDurabelsJson, mobileNumber, preapprovedPLStatus, true)
                            resolve(finalArray)
                        }).catch(function (error) {
                            let finalArray = restOfGetOffersFunction(responseBody, consumerDurabelsJson, mobileNumber, true, true)
                            resolve(finalArray)
                        });
                    }
                    if (businessLoanFlag && !personalLoanFlag) {
                        let jocataServiceBL = jocataService(mobileNumber, "businessLoan", authToken, postpreApi)
                        postpreApi.push(jocataServiceBL)
                        jocataServiceBL.then((jocataPreApprovedResponse) => {
                            preapprovedBlStatus = jocataPreApprovedResponse
                            let finalArray = restOfGetOffersFunction(responseBody, consumerDurabelsJson, mobileNumber, true, preapprovedBlStatus)
                            resolve(finalArray)
                        }).catch(function (error) {
                            let finalArray = restOfGetOffersFunction(responseBody, consumerDurabelsJson, mobileNumber, true, true)
                            resolve(finalArray)
                        });
                    }
                    if (!personalLoanFlag && !businessLoanFlag) {
                        let finalArray = restOfGetOffersFunction(responseBody, consumerDurabelsJson, mobileNumber, true, true)
                        resolve(finalArray)
                    }
                }
                else {
                    preApprovedOffer = [{
                        "title": "Personal Loan",
                        "productName": "Personal Loan",
                        "imgIcon": "images/icons/icons-16.png",
                        "description": "Fulfil Your financial Needs",
                        "applyNowBtn": "https://www.tatacapital.com/personal-loan/learn-about-personal-loan.html",
                        "knowMore": "https://www.tatacapital.com/personal-loan/learn-about-personal-loan.html",
                        "offerID": "",
                        "serviceChange": false,
                    },
                    {
                        "title": "Home Loan",
                        "productName": "",
                        "imgIcon": "images/icons/icons-17.png",
                        "description": "Customized Home loan solutions",
                        "applyNowBtn": "https://www.tatacapital.com/online/loans/home-loans/#/apply-now-home-loan.html",
                        "knowMore": "https://www.tatacapital.com/home-loan/learn-about-home-loan.html",
                        "serviceChange": false
                    },
                    {
                        "title": "Used Car Loan",
                        "productName": "AUTO LOAN",
                        "imgIcon": "images/icons/icons-18.png",
                        "description": "Financing with easy repayment options",
                        "applyNowBtn": "https://www.tatacapital.com/used-car-loan/apply-now-used-car-loan.html",
                        "knowMore": "https://www.tatacapital.com/vehicle-loan/used-car-loan/learn-about-used-car-loan.html",
                        "serviceChange": false,
                    },
                    {
                        "title": "Business Loan",
                        "productName": "",
                        "imgIcon": "images/icons/icons-19.png",
                        "description": "Avail loans to suit your business requirements",
                        "applyNowBtn": "https://www.tatacapital.com/online/loans/business-loans/home#!",
                        "knowMore": "https://www.tatacapital.com/business-loan/learn-about-business-loan.html",
                        "serviceChange": false
                    }
                    ];
                    resolve(preApprovedOffer);
                }
            } else {
                let newErrorObject = {
                    errorCode: "16",
                    preApprovedOffer: [{
                        "title": "Personal Loan",
                        "productName": "Personal Loan",
                        "imgIcon": "images/icons/icons-16.png",
                        "description": "Fulfil Your financial Needs",
                        "applyNowBtn": "https://www.tatacapital.com/personal-loan/learn-about-personal-loan.html",
                        "knowMore": "https://www.tatacapital.com/personal-loan/learn-about-personal-loan.html",
                        "offerID": "",
                        "serviceChange": false,
                    },
                    {
                        "title": "Home Loan",
                        "productName": "",
                        "imgIcon": "images/icons/icons-17.png",
                        "description": "Customized Home loan solutions",
                        "applyNowBtn": "https://www.tatacapital.com/online/loans/home-loans/#/apply-now-home-loan.html",
                        "knowMore": "https://www.tatacapital.com/home-loan/learn-about-home-loan.html",
                        "serviceChange": false
                    },
                    {
                        "title": "Used Car Loan",
                        "productName": "AUTO LOAN",
                        "imgIcon": "images/icons/icons-18.png",
                        "description": "Financing with easy repayment options",
                        "applyNowBtn": "https://www.tatacapital.com/used-car-loan/apply-now-used-car-loan.html",
                        "knowMore": "https://www.tatacapital.com/vehicle-loan/used-car-loan/learn-about-used-car-loan.html",
                        "serviceChange": false,
                    },
                    {
                        "title": "Business Loan",
                        "productName": "",
                        "imgIcon": "images/icons/icons-19.png",
                        "description": "Avail loans to suit your business requirements",
                        "applyNowBtn": "https://www.tatacapital.com/online/loans/business-loans/home#!",
                        "knowMore": "https://www.tatacapital.com/business-loan/learn-about-business-loan.html",
                        "serviceChange": false
                    }
                    ]
                }
                reject(newErrorObject);
            }
        }).catch((error) => {
            if (error.search("Currently we are not able to fetch") > -1) {
                reject(error)
            } else {
                reject("16");
            }
        })
    })
}
OverviewServiceObject.getOffersService = getOffersService


const restOfGetOffersFunction = (responseBody, consumerDurabelsJson, mobileNumber, preapprovedPLStatus, preapprovedBlStatus) => {
    var filteredArr = responseBody.records.filter(function (record) {
        if (record.Product__r.Name.toUpperCase() === personalLoan && record.Tenure_in_Months__c && record.Eligible_Amount__c) {
            if (record.Eligible_Amount__c > 50000) {
                return record.Product__r.Name.toUpperCase() === personalLoan && record.Tenure_in_Months__c;
            }
        }
    });
    if (filteredArr.length > 1) {
        let maxEligibleAmount = Math.max(...filteredArr.map(offer => offer.Eligible_Amount__c));
        filteredArr = filteredArr.filter(offer => offer.Eligible_Amount__c === maxEligibleAmount);
    }


    var filteredArrOfCd = responseBody.records.filter(function (record) {
        return record.Product__r.Name.toUpperCase() === consumerDurabels && record.Tenure_in_Months__c;
    });

    var filteredArrOfTwl = responseBody.records.filter(function (record) {
        if(record.Product__r.Name.toUpperCase() === twoWheelerLoan && record.Tenure_in_Months__c && record.Eligible_Amount__c){
            if(record.Eligible_Amount__c > 25000){
                return record.Product__r.Name.toUpperCase() === twoWheelerLoan && record.Tenure_in_Months__c;
            }
        }
    });
    if(filteredArrOfTwl.length > 1){
        let maxEligibleAmount = Math.max(...filteredArrOfTwl.map(offer => offer.Eligible_Amount__c));
        filteredArrOfTwl = filteredArrOfTwl.filter(offer => offer.Eligible_Amount__c === maxEligibleAmount);
    }

    var filteredArrOfBl = responseBody.records.filter(function (record) {
        if (record.Product__r.Name.toUpperCase() === businessLoan && record.Tenure_in_Months__c && record.Eligible_Amount__c) {
            if (record.Eligible_Amount__c > 50000) {
                return record.Product__r.Name.toUpperCase() === businessLoan && record.Tenure_in_Months__c;
            }
        }
    });
    if (filteredArrOfBl.length > 1) {
        let maxEligibleAmount = Math.max(...filteredArrOfBl.map(offer => offer.Eligible_Amount__c));
        filteredArrOfBl = filteredArrOfBl.filter(offer => offer.Eligible_Amount__c === maxEligibleAmount);
    }

    var filteredArrOfUcl = responseBody.records.filter(function (record) {
        if (record.Product__r.Name.toUpperCase() === usedCarLoan && record.Tenure_in_Months__c && record.Eligible_Amount__c) {
            if (record.Eligible_Amount__c > 50000) {
                return record.Product__r.Name.toUpperCase() === usedCarLoan && record.Tenure_in_Months__c;
            }
        }
    });
    if (filteredArrOfUcl.length > 1) {
        let maxEligibleAmount = Math.max(...filteredArrOfUcl.map(offer => offer.Eligible_Amount__c));
        filteredArrOfUcl = filteredArrOfUcl.filter(offer => offer.Eligible_Amount__c === maxEligibleAmount);
    }

    var mobileByPassOtp = "mobileNumber=" + mobileNumber + "&otpBypass=" + true;
    let preApprovedOffer = [{
        "title": "Personal Loan",
        "productName": "Personal Loan",
        "imgIcon": "images/icons/icons-16.png",
        "description": "Fulfil Your financial Needs",
        "applyNowBtn": "https://www.tatacapital.com/personal-loan/learn-about-personal-loan.html",
        "knowMore": "https://www.tatacapital.com/personal-loan/learn-about-personal-loan.html",
        "offerID": "",
        "serviceChange": false
    },
    {
        "title": "Home Loan",
        "productName": "",
        "imgIcon": "images/icons/icons-17.png",
        "description": "Customized Home loan solutions",
        "applyNowBtn": "https://www.tatacapital.com/online/loans/home-loans/#/apply-now-home-loan.html",
        "knowMore": "https://www.tatacapital.com/home-loan/learn-about-home-loan.html",
        "serviceChange": false
    },
    {
        "title": "Used Car Loan",
        "productName": "AUTO LOAN",
        "imgIcon": "images/icons/icons-18.png",
        "description": "Financing with easy repayment options",
        "applyNowBtn": "https://www.tatacapital.com/used-car-loan/apply-now-used-car-loan.html",
        "knowMore": "https://www.tatacapital.com/vehicle-loan/used-car-loan/learn-about-used-car-loan.html",
        "serviceChange": false
    },
    {
        "title": "Business Loan",
        "productName": "",
        "imgIcon": "images/icons/icons-19.png",
        "description": "Avail loans to suit your business requirements",
        "applyNowBtn": "https://www.tatacapital.com/online/loans/business-loans/home#!",
        "knowMore": "https://www.tatacapital.com/business-loan/learn-about-business-loan.html",
        "serviceChange": false
    }
    ];
    if (filteredArr.length !== 0 && !preapprovedPLStatus) {
        preApprovedOffer[0].description = 'Upto ₹ ' + parseFloat(filteredArr[0].Eligible_Amount__c).toLocaleString('en-IN') + ' for ' + filteredArr[0].Tenure_in_Months__c.toString() + ' months.';
        preApprovedOffer[0].serviceChange = false;
        preApprovedOffer[0].jocataRedirection = true;
        preApprovedOffer[0].offerID = filteredArr[0].OfferId__c.toString();
        // preApprovedOffer[0].applyNowBtn = "https://loanuat.tatacapital.com:8080/online/loans/personal-loans/apply-now-personal-loan?dtls=" + base64.encode(mobileByPassOtp);
        preApprovedOffer[0].applyNowBtn = "https://loanuat.tatacapital.com:8080/online/loans/personal-loans/apply-now-personal-loan?sourceName=Web#!";
        if (apiConfigObj.appConfig.env === "PRODUCTION") {
            // preApprovedOffer[0].applyNowBtn = "https://www.tatacapital.com/online/loans/personal-loans/apply-now-personal-loan?dtls=" + base64.encode(mobileByPassOtp);
            preApprovedOffer[0].applyNowBtn = "https://www.tatacapital.com/online/loans/personal-loans/apply-now-personal-loan?sourceName=Web#!"
        } else if (apiConfigObj.appConfig.env === "DEVELOPMENT") {
            // preApprovedOffer[0].applyNowBtn = "https://loanuat.tatacapital.com:8080/online/loans/personal-loans/apply-now-personal-loan?dtls=" + base64.encode(mobileByPassOtp);
            preApprovedOffer[0].applyNowBtn = "https://loanuat.tatacapital.com:8080/online/loans/personal-loans/apply-now-personal-loan?sourceName=Web#!";
        }

    }

    if (filteredArrOfTwl.length !== 0) {
        preApprovedOffer[2].title = "Two Wheeler Loan";
        preApprovedOffer[2].productName = "TWO WHEELER";
        preApprovedOffer[2].imgIcon = "images/icons/two-wheeler-1.png";
        preApprovedOffer[2].description = 'UPTO ₹ ' + parseFloat(filteredArrOfTwl[0].Eligible_Amount__c).toLocaleString('en-IN') + ' for ' + filteredArrOfTwl[0].Tenure_in_Months__c.toString() + ' months.';
        preApprovedOffer[2].serviceChange = true;
        preApprovedOffer[2].offerID = filteredArrOfTwl[0].OfferId__c.toString();
        preApprovedOffer[2].knowMore = "https://www.tatacapital.com/two-wheeler-loan.html";
    } else if (filteredArrOfUcl.length !== 0) {
        preApprovedOffer[2].title = "Used Car Loan";
        preApprovedOffer[2].productName = "AUTO LOAN";
        preApprovedOffer[2].description = 'Upto ₹ ' + parseFloat(filteredArrOfUcl[0].Eligible_Amount__c).toLocaleString('en-IN') + ' for ' + filteredArrOfUcl[0].Tenure_in_Months__c.toString() + ' months.';
        preApprovedOffer[2].serviceChange = true;
        preApprovedOffer[2].offerID = filteredArrOfUcl[0].OfferId__c.toString();
    }


    if (filteredArrOfBl.length !== 0 && !preapprovedBlStatus) {
        preApprovedOffer[3].description = 'Upto ₹ ' + parseFloat(filteredArrOfBl[0].Eligible_Amount__c).toLocaleString('en-IN') + ' for ' + filteredArrOfBl[0].Tenure_in_Months__c.toString() + ' months.';
        preApprovedOffer[3].serviceChange = false;
        preApprovedOffer[3].productName = "BL";
        preApprovedOffer[3].jocataRedirection = true;
        preApprovedOffer[3].offerID = filteredArrOfBl[0].OfferId__c.toString();
        // preApprovedOffer[3].applyNowBtn = "https://loanuat.tatacapital.com:8083/online/loans/business-loans?dtls=" + base64.encode(mobileByPassOtp);
        preApprovedOffer[3].applyNowBtn = "https://loanuat.tatacapital.com:8083/online/loans/business-loans?sourceName=Web#!"
        if (apiConfigObj.appConfig.env === "PRODUCTION") {
            // preApprovedOffer[3].applyNowBtn = "https://www.tatacapital.com/online/loans/business-loans?dtls=" + base64.encode(mobileByPassOtp);
            preApprovedOffer[3].applyNowBtn = "https://www.tatacapital.com/online/loans/business-loans/home?sourceName=Web#!"
        } else if (apiConfigObj.appConfig.env === "DEVELOPMENT") {
            // preApprovedOffer[3].applyNowBtn = "https://loanuat.tatacapital.com:8083/online/loans/business-loans?dtls=" + base64.encode(mobileByPassOtp);
            preApprovedOffer[3].applyNowBtn = "https://loanuat.tatacapital.com:8083/online/loans/business-loans?sourceName=Web#!"
        }

    }

    if (filteredArrOfCd.length !== 0) {
        console.log('CD OFFERCE : ', filteredArrOfCd[0]);
        consumerDurabelsJson.title = "Consumer Loan";
        consumerDurabelsJson.productName = "CONSUMER DURABLES";
        consumerDurabelsJson.description = 'Upto ₹ ' + parseFloat(filteredArrOfCd[0].Eligible_Amount__c).toLocaleString('en-IN') + ' for 24 months.';
        consumerDurabelsJson.serviceChange = true;
        consumerDurabelsJson.offerID = filteredArrOfCd[0].OfferId__c.toString();
        preApprovedOffer.splice(2, 0, consumerDurabelsJson);
    }
    checkOffersAndStore(responseBody.records);
    // 
    var newPreApprovedOffers = []
    var getDynamicOfferName = [];
    var getDynamicOfferArray = preApprovedOffer.filter((data) => {
        return data.jocataRedirection && data.jocataRedirection === true
    })

    getDynamicOfferArray.forEach((data) => {
        // 
        newPreApprovedOffers.push(data)
        getDynamicOfferName.push(data.title)
    })
    preApprovedOffer.forEach((data) => {
        if (!getDynamicOfferName.includes(data.title)) {
            newPreApprovedOffers.push(data)
        } else {

        }
    })

    return newPreApprovedOffers;
}

const checkOffersAndStore = (offersArr) => {
    var prority = ["PERSONAL LOAN", "CONSUMER DURABLES", "AUTO LOAN", "TWO WHEELER", "HOME LOAN", "BUSINESS LOAN"];
    for (var i = 0; i <= prority.length; i++) {
        if (prority[i] === "PERSONAL LOAN" && preapprovedPLStatus) {
            continue;
        }
        if (prority[i] === "BUSINESS LOAN" && preapprovedBlStatus) {
            continue;
        }
        var filterData = offersArr.filter(function (record) {
            return record.Product__r.Name === prority[i];
        });
        if (filterData.length > 0) {
            //to be continued   
            //dataStore.set('filteredOffers', filterData[0]);
            break;
        }
    }
}

const jocataService = (mobileNumber, productName, authToken, postpreApi) => {
    return new Promise((resolve, reject) => {
        var jocataPlObj = {

            "header": {
                "authToken": authToken,
                "productName": productName
            },
            "body": {
                "mobileNumber": mobileNumber
            }

        };
        var callJocataPreApprovedApi = jocataPreApproved(jocataPlObj)
        postpreApi.push(callJocataPreApprovedApi)
        callJocataPreApprovedApi.then(response => {
            if (response.header.status.toUpperCase() === "SUCCESS") {
                if (response.body.status.statusCode === "200") {
                    if (productName === "personalLoan") {
                        var activeStatusCheckForPersonalLoan = response.body.plOnlineJourneyExistingStatus;
                        console.log(activeStatusCheckForPersonalLoan);
                        resolve(activeStatusCheckForPersonalLoan);
                    }
                    if (productName === "businessLoan") {
                        var activeStatusCheckForBusinessLoan = response.body.existingCustomer;
                        resolve(activeStatusCheckForBusinessLoan);
                    }
                } else {
                    let newErrorObject = {
                        errorCode: "Currently we are not able to fetch",
                        preApprovedOffer: [{
                            "title": "Personal Loan",
                            "productName": "Personal Loan",
                            "imgIcon": "images/icons/icons-16.png",
                            "description": "Fulfil Your financial Needs",
                            "applyNowBtn": "https://www.tatacapital.com/personal-loan/learn-about-personal-loan.html",
                            "knowMore": "https://www.tatacapital.com/personal-loan/learn-about-personal-loan.html",
                            "offerID": "",
                            "serviceChange": false,
                        },
                        {
                            "title": "Home Loan",
                            "productName": "",
                            "imgIcon": "images/icons/icons-17.png",
                            "description": "Customized Home loan solutions",
                            "applyNowBtn": "https://www.tatacapital.com/online/loans/home-loans/#/apply-now-home-loan.html",
                            "knowMore": "https://www.tatacapital.com/home-loan/learn-about-home-loan.html",
                            "serviceChange": false
                        },
                        {
                            "title": "Used Car Loan",
                            "productName": "AUTO LOAN",
                            "imgIcon": "images/icons/icons-18.png",
                            "description": "Financing with easy repayment options",
                            "applyNowBtn": "https://www.tatacapital.com/used-car-loan/apply-now-used-car-loan.html",
                            "knowMore": "https://www.tatacapital.com/vehicle-loan/used-car-loan/learn-about-used-car-loan.html",
                            "serviceChange": false,
                        },
                        {
                            "title": "Business Loan",
                            "productName": "",
                            "imgIcon": "images/icons/icons-19.png",
                            "description": "Avail loans to suit your business requirements",
                            "applyNowBtn": "https://www.tatacapital.com/online/loans/business-loans/home#!",
                            "knowMore": "https://www.tatacapital.com/business-loan/learn-about-business-loan.html",
                            "serviceChange": false
                        }
                        ]
                    }
                    reject(newErrorObject);
                }
            } else {
                let newErrorObject = {
                    errorCode: "Currently we are not able to fetch",
                    preApprovedOffer: [{
                        "title": "Personal Loan",
                        "productName": "Personal Loan",
                        "imgIcon": "images/icons/icons-16.png",
                        "description": "Fulfil Your financial Needs",
                        "applyNowBtn": "https://www.tatacapital.com/personal-loan/learn-about-personal-loan.html",
                        "knowMore": "https://www.tatacapital.com/personal-loan/learn-about-personal-loan.html",
                        "offerID": "",
                        "serviceChange": false,
                    },
                    {
                        "title": "Home Loan",
                        "productName": "",
                        "imgIcon": "images/icons/icons-17.png",
                        "description": "Customized Home loan solutions",
                        "applyNowBtn": "https://www.tatacapital.com/online/loans/home-loans/#/apply-now-home-loan.html",
                        "knowMore": "https://www.tatacapital.com/home-loan/learn-about-home-loan.html",
                        "serviceChange": false
                    },
                    {
                        "title": "Used Car Loan",
                        "productName": "AUTO LOAN",
                        "imgIcon": "images/icons/icons-18.png",
                        "description": "Financing with easy repayment options",
                        "applyNowBtn": "https://www.tatacapital.com/used-car-loan/apply-now-used-car-loan.html",
                        "knowMore": "https://www.tatacapital.com/vehicle-loan/used-car-loan/learn-about-used-car-loan.html",
                        "serviceChange": false,
                    },
                    {
                        "title": "Business Loan",
                        "productName": "",
                        "imgIcon": "images/icons/icons-19.png",
                        "description": "Avail loans to suit your business requirements",
                        "applyNowBtn": "https://www.tatacapital.com/online/loans/business-loans/home#!",
                        "knowMore": "https://www.tatacapital.com/business-loan/learn-about-business-loan.html",
                        "serviceChange": false
                    }
                    ]
                }
                reject(newErrorObject);
            }
            // resolve(response);
            // console.log(response.body.records[0].Product__r.Name);
        }).catch((error) => {
            let newErrorObject = {
                errorCode: "Currently we are not able to fetch",
                preApprovedOffer: [{
                    "title": "Personal Loan",
                    "productName": "Personal Loan",
                    "imgIcon": "images/icons/icons-16.png",
                    "description": "Fulfil Your financial Needs",
                    "applyNowBtn": "https://www.tatacapital.com/personal-loan/learn-about-personal-loan.html",
                    "knowMore": "https://www.tatacapital.com/personal-loan/learn-about-personal-loan.html",
                    "offerID": "",
                    "serviceChange": false,
                },
                {
                    "title": "Home Loan",
                    "productName": "",
                    "imgIcon": "images/icons/icons-17.png",
                    "description": "Customized Home loan solutions",
                    "applyNowBtn": "https://www.tatacapital.com/online/loans/home-loans/#/apply-now-home-loan.html",
                    "knowMore": "https://www.tatacapital.com/home-loan/learn-about-home-loan.html",
                    "serviceChange": false
                },
                {
                    "title": "Used Car Loan",
                    "productName": "AUTO LOAN",
                    "imgIcon": "images/icons/icons-18.png",
                    "description": "Financing with easy repayment options",
                    "applyNowBtn": "https://www.tatacapital.com/used-car-loan/apply-now-used-car-loan.html",
                    "knowMore": "https://www.tatacapital.com/vehicle-loan/used-car-loan/learn-about-used-car-loan.html",
                    "serviceChange": false,
                },
                {
                    "title": "Business Loan",
                    "productName": "",
                    "imgIcon": "images/icons/icons-19.png",
                    "description": "Avail loans to suit your business requirements",
                    "applyNowBtn": "https://www.tatacapital.com/online/loans/business-loans/home#!",
                    "knowMore": "https://www.tatacapital.com/business-loan/learn-about-business-loan.html",
                    "serviceChange": false
                }
                ]
            }
            reject(newErrorObject);
        });
    })
}

function convertDisbursalDate(date) {
    var splitDate = date.split('.');
    return splitDate[1] + '/' + splitDate[2] + '/' + splitDate[0];
}

function checkAgreementAnnexure(disbursalDate) {
    var disbursalDate = new Date(commonFunctionObject.replaceHyphenDate(convertDisbursalDate(disbursalDate)));
    var conditionDate = new Date(commonFunctionObject.replaceHyphenDate("10/01/2019"));
    var utc1 = Date.UTC(disbursalDate.getFullYear(), disbursalDate.getMonth(), disbursalDate.getDate());
    var utc2 = Date.UTC(conditionDate.getFullYear(), conditionDate.getMonth(), conditionDate.getDate());

    if (utc1 > utc2) {
        return true;
    } else {
        return false;
    }
}

const getQuickLinksArray = (systemName, quicklinkObject, productFamily, contractData, getLoanPositionData, rcData) => {
    var filterQuickLinks = [];
    if (systemName && systemName.toLowerCase() === "las") {
        filterQuickLinks = quicklinkObject[systemName].filter((data) => {
            return data;
        });
    } else if (systemName && systemName.toLowerCase() === "retail") {
        var productName = commonFunctionObject.getProductName(contractData)
        var productFamily = commonFunctionObject.getProductFamily(contractData)
        var activeStatus = checkActiveStatus(getLoanPositionData)
        filterQuickLinks = quicklinkObject[systemName].filter((data) => {
            if (data.name.toLowerCase() === 'foreclosure letter') {
                var lowTicketLoans = [
                    "personal loan",
                    "business loan",
                    "used car",
                ]
                if (lowTicketLoans.includes(productName.toLowerCase())) {
                    let disbursedAmount = getLoanPositionData.body.getLoanPositionDtlsReturn.disbursedAmount

                    if (disbursedAmount <= 150000 && contractData.SOURCE_SYSTEM.toUpperCase() === "FINONE") {
                        return data.product.includes(productName.toLowerCase()) && data.productFamily.includes(productFamily.toLowerCase()) && data.status.includes(activeStatus.toLowerCase())
                    }
                } else if (checkForeClosure(contractData)) {
                    return data.product.includes(productName.toLowerCase()) && data.productFamily.includes(productFamily.toLowerCase()) && data.status.includes(activeStatus.toLowerCase())
                }
            }
            else if (data.name.toLowerCase() === "agreement & annexure") {
                if (checkAgreementAnnexure(contractData.DISBURSAL_DATE)) {
                    return data.productFamily.includes(productFamily.toLowerCase()) && data.status.includes(activeStatus.toLowerCase())
                }
            } else if (data.name.toLowerCase() === "sanction letter") {

                if (data.productFamily.includes(productFamily.toLowerCase())) {
                    return data.product.includes(productName.toLowerCase()) && data.productFamily.includes(productFamily.toLowerCase()) && data.status.includes(activeStatus.toLowerCase())
                }
            } else if (data.name.toLowerCase() === "provisional it certificate") {
                if (productFamily.toLowerCase() === "tchfl" || productName.toLowerCase() === "loan against property") {
                    return true
                }
            } else if (data.name.toLowerCase() === "rc update") {
                if (jsHelper.isDef(rcData)) {
                    return true
                }
            }
            else {
                return data.product.includes(productName.toLowerCase()) && data.productFamily.includes(productFamily.toLowerCase()) && data.status.includes(activeStatus.toLowerCase())
            }
        });

    } else if (systemName && systemName.toLowerCase() === "ccod") {
        filterQuickLinks = quicklinkObject[systemName].filter((data) => {
            return data;
        });
    } else if (systemName && systemName.toLowerCase() === "wealth") {
        filterQuickLinks = quicklinkObject[systemName].filter((data) => {
            return data;
        });
    }
    return filterQuickLinks;
}
OverviewServiceObject.getQuickLinksArray = getQuickLinksArray;

const checkForeClosure = (contractData) => {
    var flag = false;
    var isRetentionFlag = contractData.productInfo.IS_RETENTION;
    if (contractData.productInfo && Object.keys(contractData.productInfo).length !== 0) {
        if (isRetentionFlag) {
            if (isRetentionFlag.toUpperCase() === 'N') {
                var productFamily = commonFunctionObject.getProductFamily(contractData);
                var productName = commonFunctionObject.getProductName(contractData);
                var checkProduct = foreclosureProductCheck(productName);
                if (checkProduct) {
                    if (productFamily.toUpperCase() === "CFAB") {
                        if (contractData.SOURCE_SYSTEM.toUpperCase() === "FINONE") {
                            flag = true;
                        }
                    }
                    else {

                    }
                }
                else {

                }

            }
            else {

            }
        } else {

        }
    }
    else {

    }
    return flag;
}

const foreclosureProductCheck = (productName) => {
    var flag = false;
    switch (productName.toLowerCase()) {
        case 'two wheeler':
            flag = true;
            break;
        case 'consumer durable':
            flag = true;
            break;
    }
    return flag;
}

const holdingStatementService = (authToken, accountNumber) => {
    return new Promise((resolve, reject) => {

        holdingStatement({
            "header":
            {
                "authToken": authToken
            },
            "body":
            {

                "loanAccountNo": accountNumber,
                "reportType": "pdf",
                "format": "2",
                "userName": "adminiaf"

            }
        }).then((holdingStatementResponse) => {
            if (holdingStatementResponse.header.status.toUpperCase() === 'SUCCESS') {
                if (holdingStatementResponse.body.RetStatus.toUpperCase() === 'SUCCESS') {
                    var pdf = holdingStatementResponse.body.Get_Holding_Statement.PDFdata;
                    var byteArray = commonFunctionObject.base64ToArrayBuffer(pdf);
                    var fileName = 'Statement.pdf';
                    var mimeType = 'application/pdf';
                    commonFunctionObject.saveByteArray(fileName, byteArray, mimeType);
                    resolve()
                } else {
                    reject("L-54")
                }
            } else {
                reject("L-53")
            }
        }).catch((error) => {
            reject("L-52")
        })
    })


}
OverviewServiceObject.holdingStatementService = holdingStatementService


const clientStatementService = (authToken, userData, accountNumber, fromDate, toDate) => {
    return new Promise((resolve, reject) => {
        clientStatement({
            "header":
            {
                "authToken": authToken
            },
            "body":
            {
                "loanAccountNo": accountNumber,
                "format": "2",
                "fromDate": fromDate,
                "toDate": toDate,
                "reportType": "pdf",
                "userName": "Adminiaf"
            }
        }).then((clientStatementResponse) => {
            if (clientStatementResponse.header.status.toUpperCase() === 'SUCCESS') {
                if (clientStatementResponse.body.RetStatus.toUpperCase() === 'SUCCESS') {
                    var pdf = clientStatementResponse.body.Client_Statement.PDFdata;
                    var byteArray = commonFunctionObject.base64ToArrayBuffer(pdf);
                    var fileName = 'Client_Statement.pdf';
                    var mimeType = 'application/pdf';
                    commonFunctionObject.saveByteArray(fileName, byteArray, mimeType);
                    resolve();
                } else {
                    reject("L-57")
                }
            } else {
                reject("L-56")
            }
        }).catch((error) => {
            reject("L-55")
        })
    })
}
OverviewServiceObject.clientStatementService = clientStatementService


function getClientStatementFromDate(userData) {
    var fromDate = '';
    var currentDate = new Date(commonFunctionObject.replaceHyphenDate(userData.CURRENT_LOGGED_IN.substr(0, 10)));
    var year = currentDate.getFullYear();
    var currentFinancialYearDate = new Date(commonFunctionObject.replaceHyphenDate('03-31-' + year.toString()));

    if (currentDate.getTime() <= currentFinancialYearDate.getTime()) {
        fromDate = (year - 1).toString() + "-03-31";
    } else {
        fromDate = year.toString() + "-03-31";
    }
    return fromDate;
}

OverviewServiceObject.getClientStatementFromDate = getClientStatementFromDate

const extractDocService = (reqObj) => {

    return new Promise((resolve, reject) => {
        extractDoc(reqObj).then((response) => {
            if (response.header.status.toUpperCase() === "SUCCESS") {
                if (response.body.retStatus.toUpperCase() == "SUCCESS") {
                    if (response.body.dmsResponse) {
                        //emailpopup
                        resolve(response)
                    }
                    else {
                        resolve("callService")
                        // sendServiceRequest(product_family, staticProductCode, loanAccNumber, webTopNo, gcid)
                    }
                }
                else {
                    resolve("callService")
                    // sendServiceRequest(product_family, staticProductCode, loanAccNumber, webTopNo, gcid)
                }

            }
            else {
                if (response.errorBody) {
                    if (response.errorBody.message !== "") {
                        var jsonParsed = JSON.parse(response.errorBody.message);
                        if (jsonParsed.retStatus !== "") {
                            if (jsonParsed.retStatus === "ERROR") {
                                resolve("callService")
                                // sendServiceRequest(product_family, staticProductCode, loanAccNumber, webTopNo, gcid)
                            }
                            else {
                                reject("22")
                            }
                        }
                        else {
                            reject("22")
                        }
                    }
                    else {
                        reject("22")
                    }
                }
                else {

                }

            }

        }).catch((error) => {
            reject("21")
        })
    })

}
OverviewServiceObject.extractDocService = extractDocService

const getBoGetUrlService = (reqObj) => {
    return new Promise((resolve, reject) => {
        getBoGetUrl(reqObj).then((response) => {
            if (response.header.status.toUpperCase() === "SUCCESS") {
                if (response.body.finalUrl !== "") {
                    window.open(response.body.finalUrl, '_blank');
                    resolve()
                }
                else {
                    console.warn("finalUrl not defined");
                    reject("17")
                }

            }
            else {
                reject("17")
            }

        }).catch(() => {
            reject("17")
        })

    })

}
OverviewServiceObject.getBoGetUrlService = getBoGetUrlService

const serviceRequestService = (reqObj, serviceDetails) => {
    return new Promise((resolve, reject) => {
        serviceRequest(reqObj).then((response) => {
            if (response !== "") {
                serviceDetails["response"] = response

                resolve(serviceDetails)
            }
            else {
                reject("191")
            }

        }).catch((error) => {
            reject(error)
        })

    })

}
OverviewServiceObject.serviceRequestService = serviceRequestService


const querySRCall = (reqObj, errorCode) => {
    ;
    return new Promise((resolve, reject) => {
        serviceRequest(reqObj).then((response) => {
            ;
            if (response.header.status.toLowerCase() == "success") {
                resolve()
            }
            else {
                reject(errorCode)
            }
        }).catch((error) => {
            reject(error)
        })

    })

}
OverviewServiceObject.querySRCall = querySRCall

const InterestCertificateServiceFinone = (requestObj, certificatename) => {
    return new Promise((resolve, reject) => {
        generateInterestCertificate(requestObj).then(response => {

            if (response.header.status === "SUCCESS") {
                if (response.body.pdfData) {
                    var pdf = response.body.pdfData
                    var byteArr = commonFunctionObject.base64ToArrayBuffer(pdf);
                    var fileName = certificatename === "finalitcertificate" ? "Final IT Certificate.pdf" : "Provisional Certificate.pdf";
                    var mimeType = "application/pdf";
                    commonFunctionObject.saveByteArray(fileName, byteArr, mimeType);
                    resolve(response)
                } else {
                    reject("24")
                }
            } else {
                reject("24")
            }
        }).catch((error) => {
            reject(error)
        })
    })
}
OverviewServiceObject.InterestCertificateServiceFinone = InterestCertificateServiceFinone

const offerToOpportunityService = (requestJson) => {
    return new Promise((resolve, reject) => {

        convertOfferToOpportunity(requestJson).then(response => {
            if (response) {

                if (response.header.status === "SUCCESS") {
                    if (response.body.status.toUpperCase() === "SUCCESS") {
                        resolve(response);
                    } else {
                        reject("74")
                    }
                } else {
                    reject("20")
                }
            }
            console.log(response)
        }).catch((error) => {
            reject("19");
        });
    })
}
OverviewServiceObject.offerToOpportunityService = offerToOpportunityService;


const InterestCertificateServiceBancs = (requestObj) => {
    return new Promise((resolve, reject) => {
        getBoGetUrl(requestObj).then(response => {
            if (response.header.status === "SUCCESS") {
                if (response.body.finalUrl) {
                    window.open(response.body.finalUrl, '_blank');
                }
                resolve()
            } else {
                reject()
            }
        }).catch((error) => {
            reject(error)
        })
    })
}
OverviewServiceObject.InterestCertificateServiceBancs = InterestCertificateServiceBancs



const serviceRequestResponseHandler = (serviceDetails) => {

    var response = serviceDetails.response;
    if (response) {
        if (response.header.status.toUpperCase() === "SUCCESS") {
            if (response.body.success === "true") {
                var serviceDetailObj = {
                    "name": serviceDetails.name,
                    "code": serviceDetails.erroCode.otherService,
                    "errorId": serviceDetails.errorId.otherService
                }
                return {
                    status: "TRUE",
                    data: response,
                    serviceName: serviceDetailObj.name,

                };
            }
            else if (response.body.success === "false") {
                var serviceDetailObj = {
                    "name": serviceDetails.name,
                    "code": serviceDetails.erroCode.otherService,
                    "errorId": serviceDetails.errorId.otherService
                }
                return {
                    status: "FALSE",
                    data: serviceDetailObj.errorId,
                    serviceName: serviceDetailObj.name,
                    response: response
                };
            }

        }
        else if (response.header.status.toUpperCase() === "FAILURE") {
            return serviceDetails.errorId.shaftService;
        }
        else {
            console.warn("Status fail");
        }

    }
    else {
        console.warn("response not found");
    }

}
OverviewServiceObject.serviceRequestResponseHandler = serviceRequestResponseHandler


// Pay Overdue Service
const payOverdueService = (payodreqObj, authToken) => {
    return new Promise((resolve, reject) => {
        getContractPgsDetails(payodreqObj).then(response => {
            if (response.header.status.toUpperCase() === "SUCCESS") {
                if (response.body.retStatus.toUpperCase() === "ERROR") {
                    if (response.body.pgsData.ErrorCode === "400") {
                        resolve(response.body.pgsData.ErrorMessage)
                    } else {
                        reject(response.body.pgsData.ErrorCode)
                    }
                } else {
                    if (response.body.totalAmount && parseInt(response.body.totalAmount) > 0) {
                        if (apiConfigObj.appConfig.env === "PRODUCTION") {
                            var pgsHref = "https://www.tatacapital.com/online/payment/tcl-payments/#/payment-gateway?pgsmsg=" + encodeURIComponent(response.body.encryptedData) + '&channel=' + response.body.channel;
                            window.open(pgsHref, "_blank");
                        }
                        else if (apiConfigObj.appConfig.env === "DEVELOPMENT") {
                            pgsHref = "https://online1.tatacapital.com:8085/#/payment-gateway?pgsmsg=" + encodeURIComponent(response.body.encryptedData) + '&channel=' + response.body.channel;
                            window.open(pgsHref, "_blank");
                        }
                        checkPgsStatus(response.body.uniqueTransactionId, authToken);
                        resolve();
                    }
                    else {
                        reject("164")
                    }
                }

            }
            else if (response.header.status === "FAILURE") {
                reject("163");
                console.error("pgs header fail");
            }

            else {
                console.warn("response not found");
            }
        }).catch((error) => {
            reject(error)
        })
    })
}
OverviewServiceObject.payOverdueService = payOverdueService;


const checkPgsStatus = (uniqueTransactionId, authToken) => {
    var reqObj = {
        "header": {
            "authToken": authToken
        },

        "body": {
            "transactionId": uniqueTransactionId,
            "transactionStatus": "IN_PROGRESS"
        }
    }
    retailUpdatePaymentTransStatus(reqObj).then((response) => {
        if (response.header.status === "SUCCESS") {
            if (response.body.status === true) {
                console.log("PAYMENT STATUS DONE SUCCESSFULLY");
            } else {
                console.log("PAYMENT STATUS FAIL ");
                console.log("PAYMENT STATUS BODY FAIL ");
            }
        } else {
            console.error("PAYMENT UPDATE STATUS HEADER FAIL");
        }
    }).catch((error) => {
        console.warn("check payment gateway status", error);
    })
}

OverviewServiceObject.checkPgsStatus = checkPgsStatus;

const getFcPgsUrlService = (reqObj) => {

    return new Promise((resolve, reject) => {
        getFcPgsUrl(reqObj).then((response) => {
            if (response) {
                if (response.header.status.toUpperCase() == "SUCCESS") {
                    if (response.body.finalUrl) {
                        window.open(response.body.finalUrl, '_blank');
                        resolve(response)
                    }
                    else {
                        reject("214")
                    }
                }
                else {
                    reject("214")
                }

            }
            else {

            }

        }).catch((error) => {
            reject("215")
        })
    })

}
OverviewServiceObject.getFcPgsUrlService = getFcPgsUrlService

const getFcNonRetentionLetterService = (reqObj) => {

    return new Promise((resolve, reject) => {
        getFcNonRetentionLetter(reqObj).then((response) => {
            if (response) {
                if (response.header.status.toUpperCase() === "SUCCESS") {
                    if (response.body.pdfData) {
                        var pdf = response.body.pdfData;
                        var byteArr = commonFunctionObject.base64ToArrayBuffer(pdf);
                        var fileName = "Foreclosure Letter.pdf";
                        var mimeType = "application/pdf";
                        commonFunctionObject.saveByteArray(fileName, byteArr, mimeType);
                        resolve(response)

                    } else if (response.body.error) {
                        reject("195")
                    }

                } else {
                    reject("195")
                }

            } else { }

        }).catch(() => {
            reject("194")
        })

    })

}
OverviewServiceObject.getFcNonRetentionLetterService = getFcNonRetentionLetterService


const AgreementAnnexureService = (requestObj) => {

    return new Promise((resolve, reject) => {

        getAgreementAndAnnexure(requestObj).then(response => {
            // if (response.header.status === "SUCCESS") {
            //     if (response.body.pdfData) {
            //         var pdf = response.body.pdfData
            //         var byteArr = commonFunctionObject.base64ToArrayBuffer(pdf);
            //         var fileName = "report.pdf";
            //         var mimeType = "application/pdf";
            //         commonFunctionObject.saveByteArray(fileName, byteArr, mimeType);
            //         resolve(response)
            //     } else {
            //         reject()
            //     }
            // } else {
            //     reject()
            // }
            resolve(response)
        }).catch((error) => {
            reject("212")
        })
    })
}


OverviewServiceObject.AgreementAnnexureService = AgreementAnnexureService

const enachDetailsService = (requestObj) => {
    return new Promise((resolve, reject) => {
        getEnachDetails(requestObj).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
}

OverviewServiceObject.enachDetailsService = enachDetailsService

const saveEMandateService = (requestObj) => {
    return new Promise((resolve, reject) => {
        saveEMandate(requestObj).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
}

OverviewServiceObject.saveEMandateService = saveEMandateService

const enachLogService = (requestObj) => {
    return new Promise((resolve, reject) => {
        enachLog(requestObj).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
}

OverviewServiceObject.enachLogService = enachLogService

const updateMandateStatusService = (requestObj) => {
    return new Promise((resolve, reject) => {
        updateMandateStatus(requestObj).then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
}
OverviewServiceObject.updateMandateStatusService = updateMandateStatusService





const billPayService = (reqObj) => {
    return new Promise((resolve, reject) => {
        billPayLink(reqObj).then((billPayResponse) => {
            if (billPayResponse.header.status.toUpperCase() === 'SUCCESS') {
                console.log(billPayResponse);
                let redirectionUrl = billPayResponse.body.link;
                resolve(redirectionUrl);
            } else {
                reject("L-54")
            }
        }).catch((error) => {
            reject("L-52")
        })
    })
}
OverviewServiceObject.billPayService = billPayService


const checkActiveStatus = (getLoanPositionData) => {
    let activeStatus = ["new account", "partially advanced", "applied for cancellation", "approved", "accepted", "request for termination", "applied for termination", "fully advanced", "active"];
    // if()
    if (activeStatus.includes(getLoanPositionData.body.getLoanPositionDtlsReturn.loanStatus.toLowerCase())) {
        return "active"
    } else {
        return "closed"
    }
}


const getSecondaryProductName = (productName) => {
    let usedCarLoanArray = ["auto loan", "car loan", "used car cnc"];
    let ceqLoanArray = ["caterpiller new", "caterpiller refinance", "ceq new", "ceq refinance", "ceq top up", "construction equipme", "construction equipment finance"]

    if (usedCarLoanArray.includes(productName.toLowerCase())) {
        return "Used Car"
    } else if (ceqLoanArray.includes(productName.toLowerCase())) {
        return "CEQ Loan"
    } else if (productName === "rural - agri allied") {
        return "Agri Loan"
    } else if (productName === "tractor finance") {
        return "Tractor Loan"
    } else if (productName === "commercial vehicle") {
        return "Commercial Vehicle Loan"
    } else {
        return productName
    }
}
OverviewServiceObject.getSecondaryProductName = getSecondaryProductName


function callAssetDetails(contracts, context, preApi) {
    return new Promise((resolve, reject) => {
        var assetsDetailsPromiseArr = [];
        var assetsDetailsAccountNumberArr = [];
        contracts.forEach(function (value) {
            var productsArr = ["TWO WHEELER", "USED CAR"];
            var product_name = jsHelper.isDef(value.productInfo.DISPLAY_NAME) ? value.productInfo.DISPLAY_NAME : value.PRODUCT;
            if (jsHelper.arrayIncludes(productsArr, product_name.toUpperCase())) {
                assetsDetailsPromiseArr.push(getAssetsDetails(value, context?.authToken, preApi));
                var accountDetailsObj = {
                    "accountNumber": value.CONTRACT_NUMBER
                }
                assetsDetailsAccountNumberArr.push(accountDetailsObj);
            }
        });
        if (assetsDetailsPromiseArr.length > 0) {
            Promise.all(assetsDetailsPromiseArr).then(function (responseArr) {
                console.log("Assets Detail response: ", responseArr);
                var assetsDetailsObj = {};
                responseArr.forEach(function (response, index) {
                    if (response.header.status.toUpperCase() === 'SUCCESS') {
                        if (response.body.retStatus === 'SUCCESS') {
                            var responseData = response.body.Response[0];
                            assetsDetailsObj[assetsDetailsAccountNumberArr[index].accountNumber] = {
                                "assetmodel": (jsHelper.isDef(responseData.assetmodel) && responseData.assetmodel.trim() !== "") ? responseData.assetmodel : "-",
                                "assetvariant": (jsHelper.isDef(responseData.assetvariant) && responseData.assetvariant.trim() !== "") ? responseData.assetvariant : "-",
                                "manufacturer": (jsHelper.isDef(responseData.manufacturer) && responseData.manufacturer.trim() !== "") ? responseData.manufacturer : "-",
                                "chasisnumber": (jsHelper.isDef(responseData.chasisnumber) && responseData.chasisnumber.trim() !== "") ? responseData.chasisnumber : "-",
                                "registrationnumber": (jsHelper.isDef(responseData.registrationnumber) && responseData.registrationnumber.trim() !== "") ? responseData.registrationnumber : "-",
                                "enginenumber": (jsHelper.isDef(responseData.enginenumber) && responseData.enginenumber.trim() !== "") ? responseData.enginenumber : "-",
                            }
                        }
                    } else if (response.header.status.toUpperCase() === 'FAILURE') {
                        if (jsHelper.isDef(response.errorBody)) {
                            if (response.errorBody.statusCode == "-1") {
                                reject("207")
                            } else {
                                reject("208")
                            }
                        } else {
                            reject("208")
                        }
                    }
                });
                resolve(assetsDetailsObj)
            }).catch(function (err) {
                // Todo
            });
        }
    })

}

OverviewServiceObject.callAssetDetails = callAssetDetails

function getAssetsDetails(value, authToken, preApi) {
    var reqObj = {
        "header": {
            "authToken": authToken
        },
        "body": {
            "contractNo": value.CONTRACT_NUMBER
        }
    };
    let promise = getAssetDetails(reqObj);
    preApi.push(promise)
    return promise
}

const whatsApptFormFieldCheck = (formfiledObject, value) => {
    var mobregx = /^[6-9]{1}[0-9]{9}$/;
    let newErrorObject = {}
    if (formfiledObject === "mobileNumber") {
        if (!mobregx.test(value)) {
            newErrorObject.mobileNumber = "Please Enter Valid Mobile Number"
        } else {
            newErrorObject.mobileNumber = ""
        }
    } else if (formfiledObject === "radio") {
        if (value === false) {
            newErrorObject.radio = "Please Select"
        } else {
            newErrorObject.radio = ""
        }
    }
    return newErrorObject
}

OverviewServiceObject.whatsApptFormFieldCheck = whatsApptFormFieldCheck
const whatsapptFormSubmitCheck = (formfiledObject) => {
    const mobregx = /^[6789][0-9]{9}$/;
    let newErrorObject = {}
    if (formfiledObject.mobileNumber === "") {
        newErrorObject.mobileNumber = "Please Enter Mobile Number"
    } else {
        if (!mobregx.test(formfiledObject.mobileNumber)) {
            newErrorObject.mobileNumber = "Please Enter Valid Mobile Number"
        } else {
            newErrorObject.mobileNumber = ""
        }
    }
    if (formfiledObject.radio === false) {
        newErrorObject.radio = "Please Select"
    }
    return newErrorObject
}

OverviewServiceObject.whatsapptFormSubmitCheck = whatsapptFormSubmitCheck

const whatsApiService = (requestObj) => {
    return new Promise((resolve, reject) => {
        whatsAppServicing(requestObj).then((response) => {
            if (response.header.status.toUpperCase() === "SUCCESS") {
                resolve()
            } else {
                reject("301")
            }
        }).catch(error => {
            reject("300")
        })
    })
}

OverviewServiceObject.whatsApiService = whatsApiService


const mostSearchService = (reqObj) => {
    return new Promise((resolve, reject) => {
        searchMaster(reqObj).then((response) => {
            if (response.header.status === "SUCCESS") {
                resolve(response.body.searchMasterDetails)
            } else {
                reject("302")
            }

        }).catch(() => {
            reject("303")
        })
    })
}
OverviewServiceObject.mostSearchService = mostSearchService

const clientProfileDetailsService = (reqObj, preApi) => {
    return new Promise((resolve, reject) => {
        var callClientProfileDetails = clientProfileDetails(reqObj)
        preApi.push(callClientProfileDetails)
        callClientProfileDetails.then(response => {
            if (response.header.status.toUpperCase() === "SUCCESS") {
                resolve(response.body.clientProfileDetails)
            } else {
                reject("W-01")
            }
        }).catch(() => {
            reject("W-02")
        })
    })

}

OverviewServiceObject.clientProfileDetailsService = clientProfileDetailsService

const paytmCustomerDetailsService = (reqObj) => {
    return new Promise((resolve, reject) => {
        getPaytmCustomerDetails(reqObj).then((response) => {
            if (response.header.status === "SUCCESS") {
                // if(response.body.paytmPaymentCustomerDeatils){
                resolve(response.body.paytmPaymentCustomerDetails)
                // }
            } else {
                reject("302")
            }

        }).catch(() => {
            reject("303")
        })
    })
}

OverviewServiceObject.paytmCustomerDetailsService = paytmCustomerDetailsService


const clientHoldingDetailsService = (reqObj, postpreApi) => {
    return new Promise((resolve, reject) => {
        var callClientHoldingDetails = clientHoldingDetails(reqObj)
        postpreApi.push(callClientHoldingDetails)
        callClientHoldingDetails.then(response => {
            if (response.header.status.toUpperCase() === "SUCCESS") {
                let holdingResponse = response.body.holdingDetails;
                resolve(holdingResponse)
            } else {
                reject("W-03")
            }
        }).catch(() => {
            reject("W-04")
        })
    })
}

OverviewServiceObject.clientHoldingDetailsService = clientHoldingDetailsService

const customerSegmentationDetailsService = (reqObj ) =>{
    return new Promise((resolve, reject) => {
        getCustomerSegmentationDetails(reqObj).then((response) =>{
            if(response.header.status.toUpperCase() ==="SUCCESS"){
                if(response.body.records.length > 0){
                    resolve(response.body.records)
                }else{
                    reject("")
                }
            }else{
                reject("")
            }
        }).catch((error)=>{
            reject(error);
        })
    })
}

OverviewServiceObject.customerSegmentationDetailsService = customerSegmentationDetailsService

const callChatBotService = (requestObj, postpreApi) => {
    return new Promise((resolve, reject) => {
        let callChatBotAPI = getChatBotCustomerDetails(requestObj)
        postpreApi.push(callChatBotAPI)
        callChatBotAPI.then((response) => {
            resolve(response)
        }).catch((error) => {
            reject(error)
        })
    })
}
OverviewServiceObject.callChatBotService = callChatBotService

export default OverviewServiceObject;


